// Common Component View Definitions

@import 'src/style/mixins';
@import 'src/style/app-vars';

.component-view{
  padding: 0 !important;

  .component-parent-title{
    padding: 15px;

    h1, h2, h3, h4{
      margin-bottom: 0;
    }

    .component-parent-subtitle {
      font-size: 0.8rem;
      display: block;
    }

    &.with-border-bottom {
      flex-wrap: wrap;
      &::after {
        content: "";
        border-bottom: 2px solid $gray-200;
        flex: 1 0 100%;
        margin-top: 10px;
      }
      padding-bottom: 15px;
    }
  }

  .component-title{
    padding: 15px;

    h1, h2, h3, h4{
      margin-bottom: 0;
    }
  }

  &.container{
    margin: 0;
  }

  .component-body {
    overflow: auto;
    // height: 100%;
    flex: 1 1 auto !important;
    padding: 0 15px;

    &.with-border {
      border: 2px solid $gray-200;
      // padding-bottom: 15px;
    }
  }

  .component-body-border {
    border: 2px solid $gray-200;
    // padding-bottom: 15px;
  }
}

@include media-breakpoint-down(md) {
  .component-view{
    .component-title{
      // padding-bottom: 0;
  
      h1, h2, h3, h4{
        margin-bottom: 0;
      }
    }
    .component-parent-title{
      // padding-bottom: 0;
  
      h1, h2, h3, h4{
        margin-bottom: 0;
      }
    }
  
    &.container {
      margin: 0;
      max-width: 100%;
    }
  
    .container {
      max-width: 100%;
    }
  }
}

@include media-breakpoint-up(md) {
  .component-view{
    padding: 15px !important;
  
    .component-parent-title{
      padding: 15px;
  
      h1, h2, h3, h4{
        margin-bottom: 0;
      }
    }
  
    .component-title{
      padding: 15px;
  
      h1, h2, h3, h4{
        margin-bottom: 0;
      }
    }
  
    &.container{
      margin: 0;
    }
  }
}

.soniox-bg{
  background-color: $color-dark-blue;
  background-image: url(/assets/sonioxBg.svg);
  background-repeat: no-repeat;

  background-position-x: 34vw;
  background-position-y: bottom;
}

.section-view {
  
  .section-title {
    border-bottom: 1px solid $gray-300;

    .soniox-bg & {
      border-bottom: 1px solid $color-dark-blue-2;
    }

    &.s-no-border {
      border: none;
    }
  }

  .section-content {
    padding: 15px;

    &.s-no-pad {
      padding: 0;
    }
    &.s-no-h-pad {
      padding: 15px 0;
    }
  }

  &.s-section-border {
    border: 2px solid $gray-200;
  }
}

.s-section {
  height: 100%;
  padding: 15px 0;

  .s-section-title {
    padding: 15px 0 0 0;
    display: flex;
    justify-content: stretch;
    align-items: stretch;

    h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
      flex: 1 0 auto;
      margin-bottom: 1rem;
    }

    .btn {
      flex: 0 1 auto;
      height: 2rem;
    }
  }

  .s-section-body {
    position: relative;
    min-height: 8rem;
  }

  .s-section-icon {
    max-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 40px;
    }
  }
}

// Sub-sections

.s-section-border {
  min-height: 100%;
  padding: 15px;
  border: 2px solid $gray-200;
  background-color: white;
}

@include media-breakpoint-up(md) {
  .s-section-border {
    padding: 15px 30px;
  }
}

.s-section-border-dashed {
  min-height: 100%;
  padding: 15px 30px;
  border: 2px dashed $gray-200;
  background-color: white;
}

.s-section-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.s-sub-section {
  padding: 15px 0;

  &.s-sub-section-separator {
    border-top: 2px dashed $gray-200;
  }
}

.s-sub-section-border {
  padding: 15px;
  border: 2px dashed $warning;
}

// Background colors

.s-section-grey-bg {
  background-color: $gray-100;
}

.s-section-blue-bg {
  background-color: $color-dark-blue;
  color: #ffffff;
}

.s-section-yellow-bg {
  background-color: $color-yellow;
  color: $color-black;
}

// TO DO: create generic box class to be used on small sub-sections
// .s-box-content {
//   border: 2px solid $gray-200;
//   padding: 1.25rem;

//   P {
//     font-weight: 400;

//     &:last-child {
//       margin-bottom: 0;
//     }
//   }
//   @include media-breakpoint-down(md) {
//     margin-bottom: 2rem;
//   }

//   ul {
//     margin-bottom: 0;
//   }

//   li {
//     font-weight: 400;

//     &:last-child {
//       margin-bottom: 0;
//     }
//   }
// }



.s-details-panel {
  border-bottom: 1px solid $gray-200;
  padding: 1rem;
}

.s-details-panel-title {
  font-size: 0.9rem;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 1rem;
  padding-top: 0.75rem;
}

.title-col {
  padding-left: 0 !important;
  padding-right: 0 !important;

  .s-details-panel-title {
    padding-top: 0;
  }
}