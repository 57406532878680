@import "style/mixins";
@import "style/app-vars.scss";

.component-dashboard-view {
  .s-section-body {
    min-height: auto;

    .more {
      display: inline-block;
      vertical-align: top;
      margin-top: 0.3rem;
      margin-left: 0.3rem;
      font-size: 0.7rem;
    }
  }
}
