@import 'style/mixins';
@import 'style/app-vars.scss';

.app-body {
  background-image: url(/assets/sonioxBgLight.svg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position-x: 34vw;
  background-position-y: bottom;

  width: 100%;
  position: relative;
  overflow-y: auto;
}