@import 'src/style/mixins';
@import 'src/style/app-vars.scss';

// Price Plans Page Section
// .s-section-price-plans{

// }

// Price Plans table
.s-pricing-plan-table {
  width: 100%;
  margin: 6rem 0 4rem 0;

  text-align: center;
}

.s-pricing-plan{

  .col-md-4 {
    padding-left: 0;
    padding-right: 0;
  }

  .s-plan {
    margin: 0;
    position: relative;
    background-color: #ffffff;
    border: 1px solid $gray-300;
  }

  .s-plan-title {
    position: relative;
    top: 0;
    font-weight: 600;
    padding: 1rem 2rem;
    margin: 0 auto;
    width: 80%;

    transform: translateY(-40%);
    display: inline-block;
    background-color: #ffffff;
    border: 1px solid $gray-300;
    // color: #ffffff;

    .app-logo {
      max-height: 3rem;
    }

    span {
      font-size: 1rem;
      text-transform: uppercase;
      letter-spacing: 2px;
    }
  }
  .s-plan-cost {
    padding: 0 1rem 2rem 1rem;
  }
  .s-plan-price {
    font-family: 'Montserrat', Arial, sans-serif;
    font-weight: 800;
    font-size: 2.4em;
    color: #34495e;
  }
  .s-plan-type {
    opacity: 0.6;
  }
  .s-plan-features {
    padding: 0;
    margin: 0;
    text-align: center;
    list-style: outside none none;
    font-size: 0.8em;
    li {
      border-top: 1px solid $gray-300;
      padding: 10px 5%;
      &:nth-child(even) {
        background: rgba(0, 0, 0, 0.08);
      }
    }
    i {
      margin-right: 8px;
      opacity: 0.4;
    }
  }
  .s-plan-select {
    button {
      font-weight: bold;
      transform: translateY(50%);
    }
  }
  .featured {
    margin-top: -1rem;
    margin-bottom: -1rem;
    background-color: $color-dark-blue;
    color: #ffffff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
    z-index: 1;
    border: 1px solid $color-dark-blue-2;

    .s-plan-title {
      color: #ffffff;
      background-color: $color-dark-blue;
      border: 1px solid $color-dark-blue-2;
    }
    .s-plan-price {
      color: #ffffff;
    }
    .s-plan-cost {
      padding: 10px 10px 20px;
    }
    .s-plan-features {
      li {
        border-top: 1px solid $color-dark-blue-2;
      }
    }
    .s-plan-select {
      padding: 20px 10px 0;
      border-top: 1px solid $color-dark-blue-2;
      button {
        background-color: $color-action-blue;
        transform: translateY(50%);
      }
    }
  }
}

.s-pricing-badge {
  width: 100%;
  .s-plan {
    border: none;
    width: 100%;
    margin: 0;

    &.featured {
      box-shadow: none;
      .s-plan-title {
        border: none;
      } 
    }
  }
  .s-plan-title {
    position: static;
    top: auto;
    font-weight: 600;
    padding: 0;
    margin: 0 auto;
    width: 100%;
    padding: 0.5rem;

    border: none;
    transform: none;

    span {
      display: block;
      text-align: center;
      margin-top: 1rem;
    }
  }
}

@media only screen and (max-width: 767px) {
  .s-pricing-plan-table {
    // .s-plan {
    //   // width: 50%;
    // }
    .s-plan-title {
      transform: translateY(0);
    }
    .s-plan-select {
      button {
        transform: translateY(0);
      }
      padding: 10px 10px 10px;
    }
    .s-plan-cost {
      padding: 20px 10px 20px;
    }
    .featured {
      .s-plan-cost {
        padding: 20px 10px 20px;
      }
      .s-plan-select {
        padding: 10px 10px 10px;
      }
      margin-top: 0;
    }
  }
}
// @media only screen and (max-width: 440px) {
//   .s-pricing-plan-table {
//     .s-plan {
//       // width: 100%;
//     }
//   }
// }

.s-pricing-plan-current {
  .s-plan-select {
    padding-top: 1rem;

    p {
      font-weight: 600;
    }
  }
}

.s-plan-usage {
  flex: 1 1 auto;
  align-self: stretch;
  display: flex;
  height: 100%;

  .s-plan-usage-chart {
    position: relative;

    width: 100%;
    border: 1px solid $gray-200;
  }
  .s-plan-usage-value {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $color-dark-blue;

    span {
      color: white;
      text-align: center;
      font-weight: bold;
      display: block;
      padding: .25rem;
    }
  }

  .label-above {
    span {
      margin-top: -2rem;
      color: $color-dark-blue;
    }
  }
}
