.a-apple-app-store-badge {
  display: flex;
  margin-top: 1rem;

  a {
    display: inline-block;
    overflow: hidden;
    border-radius: 0.3rem;
    height: 48px;

    img {
      height: 48px;
    }
  }

  .app-side-navigation & {
    padding: 1rem 2rem;
    align-items: center;
    justify-content: center;
  }
}
