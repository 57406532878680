@import "../../style/mixins";
@import "../../style/app-vars.scss";

// App Header
// - with logo and navigation
.app-header {
  background-color: $color-dark-blue;
  height: $app-header-height;

  display: flex;
  // justify-content: space-between;

  overflow-x: unset !important;
  overflow-y: unset !important;

  .spacer {
    flex: 10 0 auto;
  }

  .app-logo-box {
    position: relative;
    width: 280px;
    display: flex;
    padding: 20px;

    .app-logo-beta {
      position: absolute;
      // bottom: 3px;
      // left: 20px;
      top: 14px;
      right: -24px;
      background-color: $color-dark-blue-2;
      color: $color-action-blue;
      border-radius: 3px;
      padding: 2px 5px;
      font-size: 10px;
      line-height: 10px;
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: 1px;
    }
  }

  .app-logo {
    flex: 0 1 auto;
    height: 20px;
    margin: 0;
  }

  .app-logo-title {
    color: $color-action-blue;
    font-size: 26px;
    margin: 0;
    margin-top: 0px;
    line-height: 20px;
    font-weight: 300;
    letter-spacing: 1px;
    padding-left: 8px;
    // text-transform: lowercase;
    // text-transform: capitalize;
    display: block;
  }

  h1 {
    color: #ffffff;
    font-size: 24px;
    margin: 0;
    line-height: 40px;
    padding-top: 11px;
    font-weight: 200;
    letter-spacing: 1px;
    padding-left: 15px;
    border-left: 2px solid $color-dark-blue-2;
  }

  .main-navigation {
    flex: 0 1 auto;
    display: flex;
    z-index: 50;

    ul {
      list-style: none;

      display: flex;
      flex-wrap: nowrap;
      align-items: center;

      margin-bottom: 0;
      margin: 10px;
      padding: 0;

      li {
        flex: 0 1 auto;
        margin: 0 10px;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;

        a,
        button {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        a,
        button {
          text-transform: uppercase;
          color: #ffffff;
          font-size: 0.9em;
          font-weight: 600;

          padding: 5px 10px;

          display: flex;
          line-height: 20px;
          justify-content: space-between;
          letter-spacing: 1px;

          &:hover {
            text-decoration: none;
          }
        }

        .s-header-btn {
          background-color: transparent;
        }

        .s-header-alert {
          background-color: transparent;
          .material-icons {
            color: #ffc107;
          }
        }

        .s-header-btn {
          // background-color: $text-color-blue;
          border: none;
          color: #ffffff;
          margin: 0;
          padding: 0;
          // font-size: 0.9em;
          // font-weight: 600;
          // text-transform: uppercase;

          // padding: 5px 10px;

          // display: flex;
          // line-height: 20px;
          // justify-content: space-between;
          // letter-spacing: 1px;
        }
      }
    }
  }

  .app-header-spinner {
    top: 14px;
    position: relative;
  }

  .s-popover-wrapper {
    z-index: 20;
  }

  .s-popover-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
  }

  .s-dropdown-wrapper {
    position: relative;
    .popover {
      min-width: 160px;
      margin: 0px;
      position: absolute;
      left: auto;
      top: 2rem;
      right: 1rem;
      bottom: auto;
    }
    .popover-arrow {
      position: absolute;
      top: 0px;
      transform: translate(0px, 47px);
    }
  }
}
