@import "style/mixins";
@import "style/app-vars.scss";

.s-public-form {
  .field-error-msg {
    font-size: 100%;
    padding: 0.25rem 1rem;
    width: auto;
    margin: 0.15rem 0 0 0;
    color: white;
    background-color: #dc3545;
    border: 2px solid $color-dark-blue-2;
  }
}
