// Definition of base app layout

@import "./mixins";
@import "./app-vars";

html,
body,
.app,
#root {
  height: 100%;
}

body {
  margin: 0;
  overflow: hidden;
}

.app {
  height: 100%;
  flex-direction: column;

  @include flex-container;

  .app-main {
    height: 100%;
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;

    border-top: 1px solid $color-dark-blue-2;
    border-bottom: 1px solid $color-dark-blue-2;

    // .left {

    // }
    .middle {
      animation: fadeInFromNone 0.15s ease-out;
    }
    // .right {

    // }

    .router-component {
      position: relative;
    }
  }
}

@keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    display: block;
    opacity: 1;
  }
}

@include media-breakpoint-down(sm) {
  .app {
    height: 100%;
    flex-direction: column;

    @include flex-container;

    .app-main {
      height: 100%;
      display: flex;
      flex-direction: row;
      @include flex-layout;

      position: relative;

      .left {
        position: fixed;
        top: 61px;
        bottom: 37px;
        z-index: 20;
        background-color: $gray-100;
        overflow-y: auto;
        overflow-x: hidden;

        &.s-collapsed {
          border-right: none;
        }

        &.s-expanded {
          border-right: 2px solid $gray-200;
        }
      }
      .middle {
        z-index: 10;
        flex: 1 1 100%;
        max-width: 100%;
      }
      // .right {

      // }

      .router-component {
        position: relative;
        height: 100%;
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .app {
    height: 100%;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;

    @include flex-container;

    .app-main {
      height: 100%;
      display: flex;
      flex-direction: row;
      @include flex-layout;
      flex: 1 1 auto;

      border-top: 1px solid $color-dark-blue-2;
      border-bottom: 1px solid $color-dark-blue-2;

      .left {
        flex: 0 0 auto;
        background-color: $gray-100;
        border-right: 2px solid $gray-200;
        overflow-y: auto;
      }
      .middle {
        flex: 1 1 auto;
        @include flex-layout;
        overflow-y: auto;
      }
      .right {
        flex: 0 0 auto;
        overflow-y: auto;
      }

      .router-component {
        flex: 1 1 auto;
        position: relative;
      }
    }

    > .router-component {
      flex: 1 1 auto;

      @include flex-layout;

      > .left,
      > .right {
        width: 200px;

        background: #eee;
      }

      > .middle {
        flex: 1 1 auto;
      }
    }
  }
}
