@import 'src/style/mixins';
@import 'src/style/app-vars';

body {
  color: $gray-700;
  // font-weight: 300 !important;
}

p {
  @include s-body-text;
}

b, strong {
  font-weight: 700;
}

a {
  text-decoration: none !important;

  &:hover {
    text-decoration: none;
  }
}

li {
  @include s-body-text;
}

.s-body-text {
  @include s-body-text;
}

.s-md-body-text {
  @include s-md-body-text;
}

.s-md-intro-text {
  @include s-md-intro-text;
}

.with-s-body-text p {
  @include s-body-text;
}

.with-s-md-body-text p {
  @include s-md-body-text;
}

.with-s-md-intro-text p {
  @include s-md-intro-text;
}

.s-body-text,
.s-md-body-text,
.s-md-intro-text,
.with-s-body-text p,
.with-s-md-body-text p,
.with-s-md-intro-text p {
  color: $gray-700;
}

h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {

  &.s-with-icon {
    padding-left: 3rem;
    position: relative;

    .material-icons {
      font-size: 2rem;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}

.s-section-blue-bg {
  color: #ffffff;

  .s-body-text,
  .s-md-body-text,
  .s-md-intro-text,
  .with-s-body-text p,
  .with-s-md-body-text p,
  .with-s-md-intro-text p {
    color: #ffffff;
  }

  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    color: #ffffff;
  }
}

.s-section-yellow-bg {
  color: $color-black;

  .s-body-text,
  .s-md-body-text,
  .s-md-intro-text,
  .with-s-body-text p,
  .with-s-md-body-text p,
  .with-s-md-intro-text p {
    color: $color-black;
  }

  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    color: $color-black;
  }
}

.s-intro-list-text {
  li {
    margin-bottom: 1rem;
  }
}

h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 1rem;
}

.s-color-green {
  color: $color-action-green;
}