// Color schema

$color-dark-blue-2: #1f2f5a;
$color-dark-blue: #263971;

$color-blue: #2e438b;

$color-light-blue: #009bf8;

$color-action-blue: #00a7ff;
$color-action-green: #00df4b;

$text-dark-bg-blue: #0081d2;

$text-color-grey: #6f8691;
$text-color-blue: #7381a4;

$color-yellow: #fcdc00;
$color-black: #181818;

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;
$red:     #dc3545 !default;
$color-red: #e83e8c;

// Layout

$app-header-height: 60px;
