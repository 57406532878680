@import 'style/mixins';
@import 'style/app-vars.scss';

.public-view {
  display: flex;
  flex: 1 1 auto;

  background-color: $color-dark-blue;
  background-image: url(/assets/sonioxBg.svg);
  background-repeat: no-repeat;

  background-position-x: 34vw;
  background-position-y: bottom;

  border-bottom: 2px solid $color-blue;

  .s-box{
    background-color: $color-dark-blue;
    box-shadow: 0 0 1em 0.2em rgba(0, 0, 0, 0.5);
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: stretch;
    min-height: 300px;

    h3 {
      color: #ffffff;
    }

    a{
      color: $color-action-blue;
    }

    .s-box-content {
      padding: 2em;
      color: #ffffff;
      position: relative;
      width: 100%;
      height: 100%;
      flex: 1 1 auto;
      display: flex;
      align-items: center;
    }

    .s-box-form {
      display: block;
      width: 100%;
    }
  }
  
  .soniox-banner {
    .app-logo {
        max-width: 300px;
        margin-bottom: 2em;
    }
  
    .tag-line {
      color: #ffffff;
      font-size: 3em;
    }
  }
}
