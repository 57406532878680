@mixin flex-container {
  display: flex;
  overflow: hidden;
  > * {
    flex: 0 0 auto;
    overflow-x: hidden;
    overflow-y: auto;
  }  
}

@mixin flex-layout {
  display: flex;
  > * {
    flex: 0 0 auto;
  }  
}

@mixin s-body-text {
  font-weight: $font-weight-normal;
  // font-size: 17px;
  // line-height: 28px;
}

@mixin s-md-body-text {
  font-weight: $font-weight-normal;
  font-size: 19px;
  line-height: 32px;
}

@mixin s-md-intro-text {
  font-weight: $font-weight-normal;
  font-size: 24px;
  line-height: 36px;
}
