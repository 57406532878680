// Override default bootstrap style

// <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
//   <div class="modal-dialog">
//     <div class="modal-content">
//       <div class="modal-header">
//         <h5 class="modal-title" id="exampleModalLabel">New message</h5>
//         <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
//       </div>
//       <div class="modal-body">
//         <form>
//           <div class="mb-3">
//             <label for="recipient-name" class="col-form-label">Recipient:</label>
//             <input type="text" class="form-control" id="recipient-name">
//           </div>
//           <div class="mb-3">
//             <label for="message-text" class="col-form-label">Message:</label>
//             <textarea class="form-control" id="message-text"></textarea>
//           </div>
//         </form>
//       </div>
//       <div class="modal-footer">
//         <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
//         <button type="button" class="btn btn-primary">Send message</button>
//       </div>
//     </div>
//   </div>
// </div>

.modal{

  &.modal-md-lg {
    .modal-dialog{
      max-width: 600px;
    }
  }

  .modal-dialog{

    .modal-content{
      box-shadow: 0 0 1em 0.2em rgba(0, 0, 0, 0.5);
      border: none;
      border-radius: 0 !important;

      .modal-header{
        padding: 1rem 2rem;
        background-color: $color-dark-blue;
        color: white;
        border-radius: unset;

        &.modal-header-danger {
          background-color: #dc3545;
        }

        h4, h5, h6{
          color: white;
          font-weight: 400;
        }

        .close{
          color: white;
          text-shadow: 0 1px 0 #000000;
        }
      }
      .modal-body{
        padding: 2rem;
        padding-bottom: 1rem;

        .btn-row{
          display: flex;
          justify-content: space-between;
          padding: 1rem 0;
        }
      }
      .modal-footer{
        border-top: none;
        border-radius: unset;
      }
    }

    .btn-close {
      color: #ffffff;
      filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(346deg) brightness(104%) contrast(97%);
    }
  }

  &.modal-drawer-right {
    .modal-dialog{
      position: fixed;
      margin: auto;
      width: 600px;
      height: 100%;

      transform: translate(0%, 0, 0);
      min-height: 100vh;

      .modal-content{
        // flex: 1 1 100%;
        height: 100%;
        overflow-y: auto;
      }
    }

    &.fade {
      .modal-dialog {
        right: -600px;
        transition: opacity 0.3s linear, right 0.3s ease-out;
      }

      &.in {
        .modal-dialog {
          right: 0;
        }
      }

      &.show {
        .modal-dialog {
          right: 0;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .modal{

    &.modal-md-lg {
      .modal-dialog{
        max-width: 800px;
      }
    }
  
    &.modal-drawer-right {
      .modal-dialog{
        width: 800px;
      }
  
      &.fade {
        .modal-dialog {
          right: -800px;
        }
      }
    }
  }
}