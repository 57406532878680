@import 'style/mixins';
@import 'style/app-vars.scss';

// App Footer
.app-footer {
  background-color: $color-dark-blue;
  color: #fff;

  display: flex;
  
  &.normal {
    padding: 1em;
    flex-direction: column;
    align-items: center;

    .splash {
      padding: 1em;
    }
    .app-logo{
      display: block;
      height: 100px;
    }
    .colophon {
      padding: 1em;
    }
  }

  &.condense {
    justify-content: space-between;

    .splash {
      padding: 0.5em;
    }
    .app-logo{
      display: none;
    }

    .colophon {
      padding: 0.75em;
      font-size: 0.75em;
      // color: $text-color-blue;
    }
  }
}