@import 'style/mixins';
@import 'style/app-vars.scss';

.s-status-label {
  // color: initial;
  color: #ffffff;
  text-transform: uppercase;
  
  span {
    padding-top: 3px;
    display: block;
  }
}