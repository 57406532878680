@import "style/mixins";
@import "style/app-vars.scss";

.component-billingActivity {
  .s-section-border {
    border: 2px solid $gray-200;
  }

  table tr:hover {
    cursor: pointer;

    td {
      background-color: $gray-100;
    }
  }
}

.component-billingActivityDetails {
  .invoice-status .material-icons {
    vertical-align: top;
  }
  .btn-invoice {
    float: right;
  }
}

.pagination-container {
  display: flex;
  justify-content: center;
}
