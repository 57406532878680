@import 'style/app-vars.scss';

body.reflex-col-resize {
  cursor: col-resize;
}

body.reflex-row-resize {
  cursor: row-resize;
}

// Re-Flex Container
.reflex-container {
  justify-content: flex-start; /* align items in Main Axis */
  align-items: stretch;        /* align items in Cross Axis */
  align-content: stretch;
  display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;      /* TWEENER - IE 10 */
  display: -webkit-flex;     /* NEW - Chrome */
  display: flex;
  position: relative;
  
  height: 100%;
  width: 100%;
}

.reflex-container.horizontal {
  flex-direction: column;
  min-height: 1px;
}

.reflex-container.vertical {
  flex-direction: row;
  min-width: 1px;
}

//Re-Flex Element
.reflex-container > .reflex-element {
  position: relative;
  overflow: auto;
  height: 100%;
  width: 100%;
}

.reflex-container.reflex-resizing > .reflex-element {
  pointer-events: none;
  user-select: none;
}

.reflex-container > .reflex-element > .reflex-size-aware {
  height: 100%;
  width: 100%;
}

//Re-Flex Splitter
.reflex-container > .reflex-splitter {
  background-color: $color-dark-blue-2;
  z-index: 100;
}

.reflex-container > .reflex-splitter.active,
.reflex-container > .reflex-splitter:hover {
  background-color: $color-dark-blue;
  transition: all 1s ease;
}

.horizontal > .reflex-splitter {
  border-bottom: 1px solid $color-dark-blue-2;
  border-top: 1px solid $color-dark-blue-2;
  cursor: row-resize;
  width: 100%;
  height: 2px;
}

.reflex-element.horizontal .reflex-handle {
  cursor: row-resize;
  user-select: none;
}

.reflex-container.horizontal > .reflex-splitter:hover,
.reflex-container.horizontal > .reflex-splitter.active {
  border-bottom: 1px solid $color-action-blue;
  border-top: 1px solid $color-action-blue;
}

.reflex-container.vertical > .reflex-splitter {
  border-right: 1px solid $color-dark-blue-2;
  border-left: 1px solid $color-dark-blue-2;
  cursor: col-resize;
  height: 100%;
  width: 2px;
}

.reflex-element.vertical .reflex-handle {
  cursor: col-resize;
  user-select: none;
}

.reflex-container.vertical > .reflex-splitter:hover,
.reflex-container.vertical > .reflex-splitter.active {
  border-right: 1px solid $color-action-blue;
  border-left: 1px solid $color-action-blue;
}

//Re-Flex Splitter reflex-thin
.reflex-container > .reflex-splitter.reflex-thin {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
  opacity: 0.2;
  z-index: 100;
}

.reflex-container > .reflex-splitter.reflex-thin.active
.reflex-container > .reflex-splitter.reflex-thin:hover {
  transition: all 1.5s ease;
  opacity: 0.5;
}

.reflex-container.horizontal > .reflex-splitter.reflex-thin {
  border-bottom: 8px solid rgba(255, 255, 255, 0);
  border-top: 8px solid rgba(255, 255, 255, 0);
  height: 17px !important;
  cursor: row-resize;
  margin: -8px 0;
  width: 100%;
}

.reflex-container.horizontal > .reflex-splitter.reflex-thin.active,
.reflex-container.horizontal > .reflex-splitter.reflex-thin:hover {
  border-bottom: 8px solid $color-action-blue;
  border-top: 8px solid $color-action-blue;
}

.reflex-container.vertical > .reflex-splitter.reflex-thin {
  border-right: 8px solid rgba(255, 255, 255, 0);
  border-left: 8px solid rgba(255, 255, 255, 0);
  width: 17px !important;
  cursor: col-resize;
  margin: 0 -8px;
  height: 100%;
}

.reflex-container.vertical > .reflex-splitter.reflex-thin.active,
.reflex-container.vertical > .reflex-splitter.reflex-thin:hover {
  border-right: 8px solid rgba(228, 228, 228, 1);
  border-left: 8px solid rgba(228, 228, 228, 1);
}