@import 'style/mixins';
@import 'style/app-vars.scss';

.s-box-loading {
  background-color: rgba($color-dark-blue, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .s-box-loading-message {
    flex: 0 0 auto;
    color: #ffffff;
    padding-top: 1rem;
  }

  &.s-view-loading {
    background-color: rgba(white, 0.75);

    .s-box-loading-message {
      flex: 0 0 auto;
      // color: $color-dark-blue;
      color: $gray-600;
      padding-top: 1rem;
    }
  }

  .console-view & {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    
    .s-box-message-icon {
      align-self: center;
    }
  }

  &.s-show-as-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    
    .s-box-message-icon {
      align-self: center;
    }
  }

  &.s-show-as-static {
    padding: 1rem;
  }
}