@import '~bootstrap';
@import '../../style/mixins';
@import '../../style/app-vars';

.app-side-drawer-overlay {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0,0,0,0.1);
}

.app-side-drawer {
  position: fixed;
  top: 61px;
  bottom: 37px;

  border-right: 2px solid $gray-200;
  background-color: white;

  overflow: hidden;
  display: flex;
  flex-flow: column;
  z-index: 50;

  &.s-from-right {
    right: 0;
    left: auto;
    transition: width 0.5s ease;
    border-left: 2px solid $gray-200;
  }

  .app-side-drawer-header {
    position: relative;
    background-color: $gray-100;
    padding: 1rem 3rem;

    h1, h2, h3, h4{
      margin-bottom: 0;
    }
  }

  .app-side-drawer-body {
    flex: 1 1 auto;
    background-color: white;
    // padding: 2rem 3rem;
    max-height: 100%;
    overflow: auto;
    position: relative;
  }

  .btn-drawer-close {
    position: absolute;
    left: 9px;
    top: 16px;  
  }
}

@include media-breakpoint-down(md) {

}

@include media-breakpoint-up(md) {
  .app-side-drawer {
    max-width: 600px;
    min-width: 500px;
  }
}

@include media-breakpoint-up(xl) {
  .app-side-drawer {
    max-width: 960px;

    // .app-side-drawer-body {
      
    // }
  }
}