@import '~bootstrap';
@import '../../style/mixins';
@import '../../style/app-vars.scss';

.app-side-navigation {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    font-weight: 400;
  }

  li {
    margin: 0;
    padding: 0;

    border-bottom: 1px solid $gray-300;
    font-weight: 400;

    &.s-space-top {
      margin-top: 1.5rem;
      border-top: 1px solid $gray-300;
    }

    &.s-nav-spave-header {
      background-color: $gray-200;
      color: $gray-700;
      padding: 5px 10px 5px 15px;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.75rem;
    }

    // &.s-space-bottom {
    //   margin-bottom: 0.5rem;
    //   border-bottom: 1px solid $gray-300;
    // }

    .s-nav-inactive,
    a {
      // padding: 0.75rem 0.75rem 0.75rem 0.75rem;
      padding: 10px 10px 10px 10px;

      color: $text-color-grey;

      height: 51px;

      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;

      .s-icon {
        max-width: 24px;
      }

      span {
        display: none;
        flex: 0 0 auto;
        // padding-top: 2px;

        flex: 1 0 auto;
      }

      .badge {
        flex: 0 0 auto;
        color: white;
        height: 20px;
        width: 20px;
        line-height: 20px;
        text-align: center;
        padding: 0;
      }

      &:hover {
        background-color: $gray-200;
        text-decoration: none;

        .s-icon {
          color: $color-dark-blue;
        }
      }

      &.is-active {
        background-color: $gray-300;
        color: $color-dark-blue;

        .s-icon {
          color: $color-dark-blue;
        }
      }
    }

    .s-nav-inactive {
      color: $gray-400;

      &:hover {
        background-color: transparent;
        text-decoration: none;

        .s-icon {
          color: $gray-400;
        }
      }
    }
  }

  // ul {
  //   list-style: none;
  //   margin: 0;
  //   padding: 0;

  //   li {
  //     border-top: 1px solid $gray-300;
  //     &:last-child {
  //       border-bottom: 0;
  //     }

  //     a {
  //       padding: 0.5rem 2rem 0.5rem 3.5rem;
  //       font-size: 0.9rem;

  //       &:hover {
  //         background-color: transparent;
  //         text-decoration: none;
  //         color: $color-dark-blue;
  
  //         .s-icon {
  //           color: $color-dark-blue;
  //         }
  //       }
  //     }
  //   }
  // }

  &.s-expanded {
  
    li {
      .s-nav-inactive,
      a {
        padding: 0.75rem 2rem 0.75rem 1rem;
  
        .s-icon {
          margin-right: 1rem;
        }
        span {
          display: inline-block;
        }
      }
    }
  }

  &.s-mini-size {
    min-width: auto;
    width: 54px;
    overflow: hidden;
    ul {
      li {
        .s-nav-inactive,
        a {
          // padding: 0.75rem 0.25rem 0.75rem 0.25rem;
          padding: 10px 15px 10px 15px;

          .s-icon {
            margin-right: 0;
          }
        }
        span {
          display: none !important;
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .app-side-navigation {
    width: 0;
    overflow-x: hidden;
    ul {
      width: 0;
    }
    &.s-expanded {
      width: 280px;
      overflow-x: visible;
      ul {
        width: 280px;
      }
      
      li {
        .s-nav-inactive,
        a {
          // padding: 0.75rem 2rem 0.75rem 1rem;
          padding: 10px 15px 10px 15px;
    
          .s-icon {
            // margin-right: 1rem;
            margin-right: 15px;
          }
          span {
            display: inline-block;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .app-side-navigation {
    li {
      .s-nav-inactive,
      a {
        // padding: 0.75rem 2rem 0.75rem 1rem;
        padding: 10px 30px 10px 15px;
  
        .s-icon {
          // margin-right: 1rem;
          margin-right: 15px;
        }
        span {
          display: inline-block;
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .app-side-navigation {
    min-width: 280px;
    padding-top: 2rem;
  }
}