@import 'style/app-vars.scss';

.s-public-form {

  .form-control{
    border-radius: 0;
    border-color: $color-dark-blue-2;
    border-width: 2px;
  }

  .form-group {
    .form-label{
      font-weight: 600;
    }
  }

  .btn-row {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
  }

  .btn{
    border-radius: 0;
    display: inline-block;
    background-color: $color-action-blue;
    text-transform: uppercase;
    font-size: 1em;
    letter-spacing: 2px;
  }

  .invalid-feedback{
    font-size: 100%;
    padding: 0.5rem 1rem;
    width: auto;
    margin: 0.25rem 0 0 0;
    color: white;
    background-color: #dc3545;
    border: 2px solid $color-dark-blue-2;
  }

  .invalid-non-field-feedback{
    font-size: 100%;
    padding: 0.5rem 1rem;
    width: auto;
    margin: 0.25rem 0 0 0;
    color: white;
    background-color: #dc3545;
    border: 2px solid $color-dark-blue-2;
  }
}

.s-public-form-btn {
  border-radius: 0;
  display: inline-block;
  background-color: $color-action-blue;
  text-transform: uppercase;
  font-size: 1em;
  letter-spacing: 2px;

  &:hover,
  &:focus {
    background-color: $color-action-blue;
  }
}