.pagination{
  border-radius: 0;

  .page-item {
    .page-link {
      border-radius: 0;
    }
  }

  &.s-pagination {
    position: sticky;
    top: 0;
    background-color: white;

    align-items: center;
    justify-content: center;
    // margin-top: 10px;

    border: 2px solid $gray-200;
    border-top: none;
    // border-bottom: 1px solid $gray-300;
    margin-bottom: 0;

    .page-item {
      .page-link {
        border: 0;
        border-left: 1px solid $gray-300;

      
      }

      &:first-child{
        .page-link {
          border-left: none;
        }
      }
    }
  }
}