@import '~bootstrap/scss/bootstrap.scss';

@import 'style/mixins';
@import 'style/app-vars.scss';

.item-viewer{
  @include flex-container;
  width: 100%;
  flex-flow: column;

  .item-viewer-body{
    @include flex-container;
    border: 2px solid $gray-200;
    background-color: white;
    width: 100%;
    flex: 1 1 auto;
    position: relative;

    > .s-box-message {
      .s-box-message-body {
        display: flex;
        align-items: center;
      }
      .s-modal-component {
        margin-left: 0.5rem;
      }
    }
  }

  .item-viewer-footer{
    flex: 0 0 auto;
  }

  .item-viewer-sidebar {
    .item-viewer-filter {
      border-bottom: 2px solid $gray-200;
      padding: 5px;
      display: flex;
      align-items: center;

      form {
        flex: 1 0 auto;

        .form-control {
          border-color: $gray-200;
        }
      }

      .btn {
        flex: 0 0 auto;
        border: none;
        padding: 7px;
        margin-left: 7px;
        .s-icon {
          padding: 0;
        }
      }
    }
  }

  .item-list{
    width: 100%;

    .item-list-item{
      padding: 0.5rem 1rem;
      border-bottom: 1px solid $gray-200;
      background-color: $gray-100;
      cursor: pointer;
      min-height: 85px;

      display: flex;
      flex-direction: column;

      &:hover{
        background-color: $gray-200;
      }

      &.selected{
        background-color: $gray-300;
      }
    }

    .item-prop-row{
      flex: 1 1 auto;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;

      &.meta-data{
        flex: 0 0 auto;
      }
    }

    .item-prop{
      flex: 0 0 auto;
    }

    .item-prop-title {
      // font-size: 1rem;
      font-weight: 600;
    }

    .meta-data{
      .item-prop{
        color: $color-dark-blue;
        // font-size: 0.8rem;
        font-weight: 600;
      }
      .item-prop-date {
        font-weight: normal;
        font-size: 1rem;
      }
      .item-prop-type{
        flex: 1 1 auto;
      }
      .item-prop-cost{
        border-left: 1px solid $gray-200;
        margin-left: 0.5rem;
        padding-left: 0.5rem;
      }
      .item-prop-duration{
        border-left: 1px solid $gray-200;
        margin-left: 0.5rem;
        padding-left: 0.5rem;
      }
    }

    .item-prop-summary{
      color: $gray-600;
      font-size: 0.9rem;
      flex: 1 1 100%;
      display: block;
      overflow: hidden;
      padding: 0.25rem;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      &.no-item{
        text-align: center;
        font-style: italic;
        color: $gray-500;
        display: flex;
        justify-content: center;
        align-self: center;
      }
    }
  }

  .item-details{
    display: block;
    overflow-x: hidden;
    overflow-y: auto;
    flex: 1 1 auto;
    position: relative;

    .item-details-header{
      border-bottom: 1px solid $gray-200;
      padding: 0 15px;

      .row {
        justify-content: space-between;
      }

      .col {
        padding-top: 10px;
        padding-bottom: 10px;
      }

      .s-space-filler {
        display: none;
      }
    }

    .item-details-prop{
      color: $text-color-grey;
      font-size: 0.8rem;
      font-weight: 600;
    }

    .item-details-prop-value{
      color: $text-color-grey;
    }

    .item-details-body{
      padding: 1rem;

      .item-text {
        font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
      }
      .item-text-error {
        font-style: italic;
      }
    }

    .item-details-no-data {
      height: 100%;
      align-content: center;
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        flex: 1 1 auto;
        font-style: italic;
        text-align: center;
        color: $gray-400;
      }
    }

    .s-action-bar {
      border-left: 1px solid $gray-200;
      display: flex;
      flex-wrap: nowrap;
      
      .s-btn-action {
        display: flex;
        justify-content: center;
        align-content: center;
        flex-flow: column;

        .material-icons {
          flex: 0 0 auto;
        }
      }
    }
  }

  .s-item-type-badge{
    text-transform: uppercase;
  }
}

@include media-breakpoint-down(xs) {
  .component-item-view {
  
    .component-view{
  
      .section-view{
  
        .section-content{
          padding: 0;
        }
      }
    }
  }
  .item-viewer{
    .item-viewer-body {
      // border-right: none;
      // border-left: none;
    }
  }
}

@include media-breakpoint-down(lg) {
  .item-viewer{
    .item-list{

      .item-list-item{
        padding: 0.5rem 1rem;
        min-height: 85px;
      }

      .meta-data{
        .item-prop{
          font-size: 1rem;
        }
        .item-prop-type{
          flex: 1 1 auto;
        }
        .item-prop-cost{
          margin-left: 0.5rem;
          padding-left: 0.5rem;
        }
        .item-prop-duration{
          margin-left: 0.5rem;
          padding-left: 0.5rem;
        }
      }

      .item-prop-summary{
        font-size: 1rem;
        flex: 1 1 100%;
        padding: 0.25rem;
      }
    }

    .item-details{

      .item-details-prop{
        font-size: 0.8rem;
        font-weight: 600;
      }

      .item-details-body{
        width: 100%;
        max-width: 100%;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .item-viewer{
    .item-list{
      border-right: 2px solid $gray-200;
      width: auto;
      max-width: 340px;
      min-width: 280px;

      .item-list-item{
        padding: 0.5rem 1rem;
        border-bottom: 1px solid $gray-200;
        background-color: $gray-100;
        cursor: pointer;
        min-height: 85px;

        display: flex;
        flex-direction: column;

        &:hover{
          background-color: $gray-200;
        }

        &.selected{
          background-color: $gray-300;
        }
      }

      .meta-data{
        .item-prop{
          font-size: 0.8rem;
        }
        .item-prop-type{
          flex: 1 1 auto;
        }
        .item-prop-cost{
          margin-left: 0.5rem;
          padding-left: 0.5rem;
        }
        .item-prop-duration{
          margin-left: 0.5rem;
          padding-left: 0.5rem;
        }
      }

      .item-prop-summary{
        font-size: 0.9rem;
        flex: 1 1 100%;
        padding: 0.25rem;

        &.no-item{
          
        }
      }
    }

    .item-details{

      .item-details-header{
        padding: 0 15px;
  
        .col {
          padding-top: 10px;
          padding-bottom: 10px;
        }

        .s-space-filler {
          display: block;
        }
      }

      .item-details-prop{
        font-size: 0.8rem;
        font-weight: 600;
      }

      .item-details-body{
        padding: 1rem;
      }
    }
  }

  .item-viewer-sidebar {
    border-right: 2px solid $gray-200;
    width: auto;
    width: 320px;

    .item-list{
      border-right: none;
      width: auto;
      width: 320px;
    }
  }
}

@include media-breakpoint-up(xl) {
  .item-viewer{
    max-width: 1360px;

    .item-details{

      .item-details-prop{
        font-size: 0.8rem;
        font-weight: 600;
      }

      .item-details-body{
        padding: 1rem 1rem;
      }
    }
  }
}