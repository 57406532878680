.popover{
  box-shadow: 0 0 1em 0.2em rgba(0, 0, 0, 0.5);
  border-radius: 0;
  border: none;

  .arrow{
    &:after{
      border-bottom-color: $color-dark-blue;
    }
  }

  .popover-header{
    background-color: $color-dark-blue;
    color: white;
    border-radius: 0;

    &:before{
      border-bottom: none;
    }
  }

  .bs-popover-bottom .popover-header::before, 
  .bs-popover-auto[x-placement^="bottom"] .popover-header::before{
    border-bottom: 1px solid $color-dark-blue;
  }
  
  .popover-body{
    padding: 1rem 2rem;
    p:last-child{
      margin-bottom: 0;
    }
  }
}