@import '../../style/mixins';
@import '../../style/app-vars.scss';

.s-box-message {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  .s-box-message-icon {
    flex: 0 0 auto;
    color: #ffffff;
    padding-right: 2rem;
    align-self: start;
    max-width: 4rem;

    .material-icons {
      font-size: 3rem;
    }
  }

  .s-box-message-body {
    flex: 0 1 auto;
    color: #ffffff;

    h3 {
      margin-top: .5rem;
      margin-bottom: 1.5rem;
    }

    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.s-view-message {
    .s-box-message-icon {
      color: $gray-400;
  
      .material-icons {
        font-size: 3rem;
      }
    }
  
    .s-box-message-body {
      flex: 0 1 auto;
      color: $gray-600;
  
      h3 {
        margin-top: .5rem;
        margin-bottom: 1.5rem;
      }
  
      p {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  // .console-view & {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
    
  //   .s-box-message-icon {
  //     align-self: center;
  //   }
  // }

  &.s-show-as-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    
    .s-box-message-icon {
      align-self: center;
    }
  }

  // &.s-show-as-static {
  //   // padding: 1rem;
  // }
}