@import './app-vars.scss';

// Override default variables before the import
$body-bg: #ffffff;

$body-bg:                   $white;
$body-color:                $gray-700;
// $headings-color:            $text-color-grey;
// $headings-color:            $text-color-blue;
$headings-color:            $color-dark-blue-2;

$font-family-sans-serif: 'Open Sans', sans-serif;

$font-weight-lighter:         lighter;
$font-weight-light:           300;
$font-weight-normal:          300;
$font-weight-bold:            600;
$font-weight-bolder:          700 !important;
$headings-font-weight:        600;

$font-weight-base: $font-weight-normal;
$btn-font-weight: $font-weight-normal;
$primary: $color-blue; // $blue !default;
$secondary: $color-light-blue; // $gray-600 !default;

$primary:       $color-blue; // $blue !default;
$secondary:     $color-light-blue;// $gray-600 !default;

// $btn-border-radius:           0;
// $btn-border-radius-lg:        0;
// $btn-border-radius-sm:        0;

//@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;0,800;1,400;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,400;1,800&display=swap');

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

@import '../assets/fonts/material-icons.scss';

@import './bootstrap/alert.scss';
@import './bootstrap/table.scss';
@import './bootstrap/modal.scss';
@import './bootstrap/pagination.scss';
@import './bootstrap/popover.scss';
@import './bootstrap/button.scss';
@import './content/typography.scss';

@import './app-layout.scss';

@import './components/reFlex.scss';

@import './components/publicForm.scss';
@import './components/appForm.scss';
@import './components/itemViewer.scss';

@import './view.scss';
@import './content/subscriptionPlans.scss';