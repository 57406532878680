@import 'src/style/app-vars.scss';

.btn-cancel{
  background-color: $text-color-blue;
}

.btn {
  font-weight: 600;

  &.s-primary {
    background-color: $color-blue;
    border-color: $color-dark-blue-2;
    // font-weight: 400;

    font-size: 0.8rem;
    text-transform: uppercase;
    font-weight: 600;

    &:hover,
    &:focus,
    &:active {
      background-color: $color-dark-blue;
    }
  }

  &.s-primary-outline {
    background-color: transparent;
    border-color: $gray-300;
    border-width: 2px;
    // font-weight: 400;
    color: $color-blue;
    
    font-size: 0.8rem;
    text-transform: uppercase;
    font-weight: 600;

    &:hover,
    &:focus,
    &:active {
      border-color: $gray-300;
      color: $color-blue;
      background-color: $gray-200;
    }
  }

  &.s-btn-with-icon{ 
    display: flex;
    align-items: center;
    i {
      flex: 0 0 auto;
      padding-right: 0.5rem;
    }
    span {
      flex: 1 1 auto;
    }
  }

  &.s-icon-btn {
    background-color: transparent;
    color: $gray-600;
    border: none;
    padding: 0 0.25rem;

    &:hover,
    &:focus,
    &:active {
      background-color: transparent;
    }
  }
}